export default {
  BLANCO: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/blanco`,
    LEADS: 'leads',
    LEADS_QUERY: 'leads/query',
    LEAD: 'leads/{leadId}',
    SEND_CONTRACT: 'leads/{leadId}/send-contract',
    INVOICE: 'leads/{leadId}/invoice',
    PRICE_TAG: 'price-tag/{credit}',
    PRICE_LIST: 'price-lists',
    MONTHLY_COST: 'monthly-cost/{credit}',
    CREDIT_PRODUCTS: 'credit-products',
    SGAP_APPLICATION: 'v2/sambla-application/{leadId}',
  },

  LEASING: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/leasing`,
    LEADS: 'leads',
    LEAD: 'leads/{leadId}',
    MONTHLY_COST: 'monthly-cost/{credit}',
    PRICE_LIST: 'price-lists',
  },

  BUSINESS_FINANCE: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/business-finance`,
    LEADS: 'leads',
    LEAD: 'leads/{leadId}',
    CONTRACT: 'leads/{leadId}/contracts',
    INVOICE: 'leads/{leadId}/invoices',
    ACTIVE_STATEMENT_PDF: 'leads/engagement-report',
    MONTHLY_STATEMENT_PDF: 'transactions/monthly-statement/{period}/pdf',
    YEARLY_SUMMARY: 'transactions/yearly-summary/{year}',
    PRICE_LIST: 'price-lists',
    DAILY_COST: 'daily-cost/{credit}',
    PARTNER_DATA: 'self',
    LINES_OF_CREDIT: 'lines-of-credit',
  },

  INVOICES: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/invoices`,
    PDF: '{invoiceId}/pdf',
    PAYMENT: '{invoiceId}/payment',
  },

  ACCOUNT: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/account`,
    ME: 'me',
    LANGUAGE: 'me/language',
  },

  PARTNER: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/partner`,
    DEALER: '',
    FACILITY: 'facilities',
    EMPLOYEES: 'employees',
    KAM: 'kam',
  },

  COMMISSIONS: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/commissions`,
    YEARLY_SUMMARY: 'yearly-summary/{year}',
    MONTHLY_SUMMARY_PDF: 'monthly-summary/{period}/pdf',
  },

  SUBSCRIPTIONS: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/subscriptions`,
    CHANNELS_LIST: 'channels',
    CHANNEL: 'channels/{channel}',
    STATUSES_LIST: 'statuses',
    STATUS: 'statuses/{status}',
  },

  POINT_SHOP: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/shop`,
    ARTICLES: 'article',
    ARTICLE: 'article/{articleId}',
    ORDERS: 'orders',
    ACCOUNT: 'account',
  },

  LOOKUP: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/lookup`,
    VEHICLE: 'vehicle/{numberPlate}/{metricInKilometers}',
    PERSON: 'persons',
    ORGANIZATION: 'organizations',
  },

  TOKEN: {
    HOST: process.env.VUE_APP_PAY_BACKEND_URL,
    REVOKE_TOKEN: 'revoke-token',
  },

  USER_INVITATIONS: {
    HOST: `${process.env.VUE_APP_PAY_BACKEND_URL}/user-invitations`,
    CONSUME: 'consume',
  },
};
