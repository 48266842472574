export default {
  general: {
    add: 'Lisää',
    cancel: 'Sulje',
    close: 'Sulje',
    contactKeyAccountManagerModal: {
      noAccess: 'Sinulla ei ole oikeutta tähän tuotteeseen vielä.',
      kamName: 'Ota yhteyttä myyntiedustajaan saadaksesi oikeuden.',
    },
    copy: 'Kopio',
    edit: 'Vaihda',
    remove: 'Poista',
    save: 'Tallenna',
    saved: 'Tallennettu',
    search: 'Hae',
    startChat: 'Kysy heti chatissa',
    emptySearchResult: 'Ei tuloksia. Nollaa hakusi ja yritä uudelleen.',
    leads: {
      statuses: {
        MANUAL_INSPECTION: 'Käsittelyssä',
        ACCOUNT_ANALYSIS: 'Lisätietoja',
        SUPPLEMENT: 'Tarvitsemme lisätietoja',
        SUPPLEMENT_CONTACT_US: 'Ota yhteyttä',
        SUPPLEMENT_ACTION: 'Lisätietoja',
        APPROVED: 'Hyväksytty',
        DENIED: 'Hylätty',
        SENT_TO_CUSTOMER: 'Tarvitsemme allekirjoituksen',
        SIGNED: 'Sopimus allekirjoitettu',
        PAID_OUT: 'Maksettu',
        EXPIRED: 'Vanhentunut',
        ABORTED: 'Peruuntunut',
        PROCESSING: 'Käsittelyssä',
        FINISHED: 'Valmistunut',
      },
    },
    navigation: {
      back: 'Takaisin',
      sectionTitle: {
        apply: 'Hae',
        otherServices: 'Muut palvelut',
        administration: 'Ylläpito',
      },
      personalLoan: 'Yksityislaina',
      leasing: 'Leasing',
      inventoryFinancing: 'Myyjä rahoitus',
      market: 'Market',
      pointShop: 'Pistekauppa',
      priceTag: 'Hintalappu',
      report: 'Raportit',
      commission: 'Provisio',
      organisation: 'Organisaatio',
      myProfile: 'Minun profiili',
      logOut: 'Kirjaudu ulos',
      loanApplication: 'Lainahaku',
      cancel: 'Sulje',
      home: 'Kotisivu',
    },
    pageTitle: {
      titlePrefix: 'MyMoney Pay',
      activateAccount: 'Aktivoi tili',
      home: 'Kotisivu',
      blancoList: 'Lainahakemukset',
      blancoDetails: 'Lainan tiedot',
      blancoApply: 'Lainahakemus',
      leasingList: 'Leasing',
      leasingDetails: 'Leasing-tiedot',
      leasingApply: 'Leasing-hakemus',
      inventoryFinancingList: 'Myyjä rahoitus',
      inventoryFinancingDetails: 'Varastorahotiushakemus',
      inventoryFinancingPayment: 'Varastorahotiusmaksu',
      inventoryFinancingApply: 'Varastorahotiushakemus',
      market: 'Market',
      marketDetail: 'Market kohde',
      marketConfirm: 'Vahvista osto',
      pointShopList: 'Piste-kauppa',
      pointShopArticle: 'Artikkeli',
      pointShopCheckout: 'Kassalle',
      priceTag: 'Hintalappu',
      report: 'Rapportit',
      commission: 'Provisio',
      organisation: 'Organisaatio',
      user: 'Käyttäjä',
      userMyProfile: 'Profiilini',
      maintenance: 'Huolto käynnissä',
    },
    company: 'Yritys',
    email: 'Sähköpostiosoite',
    date: 'Päiväys',
    mobilePhoneNumber: 'Puhelinnumero',
    phone: 'Puhelin',
    sortingFilterLabel: 'Lajittele',
    sortingFilter: {
      createdTimeDesc: 'Uusimmat ensin',
      latestAsc: 'Vanhin',
      latestDesc: 'Viimeisin',
      priceAsc: 'Halvin',
      priceDesc: 'Kallein',
    },
    imageCarousel: {
      imageCounter: 'Kuva {current} / {total}',
    },
    genericError: 'Jokin meni pieleen',
    technicalError: 'Tekninen virhe, ota yhteyttä meihin',
    workingDays: 'Arkipäivät:',
    saturday: 'Lauantai:',
    sunday: 'Sunnuntai:',
    openingHourClosed: 'Suljettu',
    download: 'Lataa',
    pointsEarned: '{pointsEarned} Pisteet',
  },
  'activate-account': {
    chooseAPassword: 'Valitse salasana',
    passwordRequirements:
      'Salasanan pitää sisältää vähintään 8 merkkejä, vähintään yhden pienen kirjaimen (a-z) ja yksi iso kirjain (A-Z) sekä numero (0-9).',
    yourPassword: 'Salasana',
    confirmPassword: 'Syötä salasana uudelleen',
    passwordMismatch: 'Salasanat eivät täsmää',
    passwordNotLongEnough: 'Salasanasi ei ole tarpeeksi pitkä.',
    passwordDoNotContainLowercaseLetter:
      'Salasanasi pitää sisältää vähintään yhden pienen kirjaimen: a-z.',
    passwordDoNotContainUppercaseLetter:
      'Salasanasi pitää sisältää vähintään yhden ison kirjaimen: A-Z.',
    passwordDoNotContainAnyNumbers:
      'Salasanasi pitää sisältää vähintään yhden numeron: 0-9.',
    savePassword: 'Tallenna salasana',
    passwordError:
      'Salasanasi ei täytä yllä mainitut turvallisuusvaatimukset, valitse uusi salasana.',
    resetTokenError:
      'Tämä linkki on vanhentunut tai käytetty. Kokeile uudelleen kirjautua sisään Pay:ssä tai ota meihin yhteyttä.',
  },
  home: {
    greetings: 'Hei {user}',
    searchPlaceholder: 'Hae nimellä tai henkilötunnuksella',
    myLastApplications: 'Viimeisimmät hakemukseni',
    showMyApplications: 'Näytä kaikki hakemukseni',
    priceSign: {
      title: 'Hintalappu',
      body: 'Luo tulostettava hintalappu joka näyttää asiakkaan kuukausierän',
    },
    emptySearch: 'Ei löydy hakemusta “{search}”',
    showAllVehicles: 'Näytä kaikki ajoneuvot',
    latestAddedVehicles: 'Uusimmat ajoneuvot',
    market: 'Market',
    pointShop: 'Pistekauppa',
    latestReceived: 'Uusimmat',
    showAllProducts: 'Näytä kaikki tuotteet kaupassa',
    youHaveXXXPoints: {
      beforePoints: 'Teillä on',
      afterPoints: 'shoppailua varten',
    },
  },
  loan: {
    searchResultsMatching: '{totalElements} hakuja jotka osui  "{searchTerm}"',
    resetSearch: 'Poista haku',
    resetFilter: 'Nollaa',
    personFilterLabel: 'Näytä',
    personFilterDefault: 'Kaikki',
    personFilterOnlyMine: 'Vain minun',
    facilityFilterLabel: 'Osasto',
    facilityFilterDefault: 'Kaikki',
    statusFilterLabel: 'Tila',
    statusFilterDefault: 'Kaikki',
    firstApplication: 'Aloita ensimmäinen hakemus!',
    listLoadingError: {
      somethingWentWrong: 'Jokin meni pieleen!',
      tryAgainLater:
        'Emme pystyneet hakemaan hakemiuksianne tällä hetkellä. Yrtiä hetken kulutta uudelleen',
      retry: 'Yritä uudelleen',
    },
    status: 'Tila',
    reference: 'Referenssi',
    referenceId: 'Referenssi ID',
    contractSigned: 'Sopimus allekirjoitettu',
    amount: 'Määrä',
    createdTime: 'Hakemuksen päiväys',
    desiredInterestRate: 'Haluttu korko',
    interestFree: 'Koroton',
    applicationIsProcessing:
      'Työskentelemme kovasti ratkaisun löytämiseksi ja otamme sinuun yhtyettä mahdollisimman pian.',
    returnBackToArchive: 'Taikasin kotisivulle',
    goToLead: 'Siirry hakemukseen',
    makeNewApplication: 'Tee uusi hakemus',
    otherTerms: 'Muut ehdot',
    iAcceptTerms: 'Hyväksyn käyttöehdot.',
    informedAboutCreditCheck:
      'Asiakas on iloimtettu, että luottotiedot tarkistetaan.',
    splitYourPayment: 'Jaa maksusi alkaen',
    costPerMonth: '{monthlyPayment}/kk',
    interest: 'Korko',
    loanType: 'Hakemuksen muto',
    sendContractTitle: 'Lainaustiedot',
    sendContractFirstMessage:
      'Tätä lainaa voidaan käyttää ainoastaan rahoittamaaan asiakkaan ostamaa tuotetta yritykseltäsi. Jos ostosi jostain syystä peruuntuu, sinun on aina maksettava lainsaumma takaisin MyMoneylle',
    sendContractSecondMessage:
      'Huomaa, että te ette saa koskaaan maksaa koko lainaa tai osaa siitä suoraaan lopulliselle asiakkaalle',
    sendContractApprove: 'Vahvistaan sekä hyväksyn',
    signingLink: 'Allekirjoituslinkki',
    sendAgreement: 'Lähetä sopimus',
    terms: {
      SE: {
        general: {
          text: 'Hyväksyn {0} ja vakuutan, että käyttäjänä noudatan {1} ohjeita.',
          link1: 'MyMoney Payn käyttöehdot',
          link2: 'KKrL:n lain (2010:1846)',
        },
        businessAreaTerms: {
          MEDICAL:
            'Asiakkaalta on pyydetty suostumus siihen, että Mymoneylle luovutetaan tietoja käsittelystä ja asiakkaan henkilötiedoista siihen määrään kuin se on tarpeen luottoahakmuksen arvioimiseksi ja mahdollisten riitojen hallitsemiseksi sekä pistokokeita varten.',
        },
      },
      FI: {
        general: {
          text: '{0} MyMoney Pay',
          link: 'Hyväksyn käyttöehdot',
        },
        asiakastieto: {
          text: 'Annan suostumukseni Suomen {0} ylläpitämän kyselyjärjestelmän mukaiseen tietojen luovutukseen luotonmyöntäjille.',
          link: 'Asiakastieto Oy:n',
        },
      },
    },
  },
  loanDetails: {
    application: 'Hakemus',
    payments: 'Maksut',
    documents: 'Asiakirja',
  },
  loanTermsModal: {
    modalTitle: 'Käytäjäehdot Pay',
    aboutService: {
      title: 'Tietoja palvelusta',
      text: 'MyMoney Pay palvelussa (alla kutsuttu palvelu) yhteistyökumppanit (alla kutsuttu kauppiaat) voivat hakea lainoja loppuasiakaidenssa puloesta rahoittaakseen tavaroiden tai palveluiden oston. Henkilöt töissä kaupian luona (alla kustuttu käyttäjät)jotka toimiviat kauppiaan edustajana, saavat kayttää palvelua tillin kautta. Kayttämällä palvelua Kauppiaat sekä Käyttäjät hyväksyvät käytöehdot ja noudattavat niitä. Palvelun tarjoaa MyMoney OY, 3303458-1.',
    },
    gdpr: {
      title: 'GDPR',
      text: '25. Toukokuuta 2018, tulli voimaan uusi EU:n laajuinen säntelykehys, yleinen tietosuoja-asetus General Data Protection Regulation, (GDPR). Tarkoitusenna on suojella henkilöita sääntelemällä sitä, miten henkilötietoja voidaan käsitellä.',
    },
    handlingOfPersonalData: {
      title: 'Henkilötunnuksen käsittely',
      text: 'Henkilötiedot rekisteröidään kuin luot käytäjätilin Palvelussa. Henkilötietoja käsittelevät MyMoney sekä muut yrytikset, joiden kanssa MyMoney tekee yhetystyöta, Palveluun hallinoimiseksi ja tarjoamiseksi. Tietoja käsitellään myös, jotta MyMoney täyttää lakisääteiset velvoitteensa. Voimme käytää henkilötietoja myös markkina-analyysiin, markkinoinnin ja riskienhallintaan. Voit lukea lisätietoja sekä oikeuksiasi ositteessa.',
      link: 'tietosuojakytäntöä',
      endText: 'Josta löydät MyMoneyen täydelliset tietosujakäytännöt.',
    },
    creditInformation: {
      title: 'Lutottotiedot',
      text: 'Kauppiojden on ilomoitettava hakijalle (hakijoille, että MyMoney voi hankia luottotietoja joko itse tai useamman yhteistyökumppanin kautta. Luttotiedot voidaan hankkia Asiakastieto OY.',
    },
    personalDataController: {
      title: 'Rekisterinpitäjä',
      text: 'MyMoney OY, jonka organisaationumero on MyMoney OY, 3303458-1, on antamasi tietojen rekisterinpitäjä.',
    },
    idControl: {
      title: 'Henkilötarkastus',
      text: 'Kaupijaalla on aina tarkistettava hakemuksen kohteena olevan henkilön (henkilöiden) tunnukset, ennen hakemuksen tekemistä. Käyttäen Suomen viranomaisen myöntämää hyväksyttyä henkilöllisyystodisusta.',
    },
    pointShop: {
      title: 'Pistekaupa',
      text: 'Kaupijoilla on mahdollisuus osallistua MyMoney pistekauppaan, jossa saadut pisteet voidaan käyttää palkintojhin. Kaikki saadut pisteet sekä palkinot kuuluvat Kaupijaalle riippumatta siitä, mikä kauppias on lähetänyt pistehakemuksen. Kaupiaat ovat vastuussa pistekauppan osallistumisesta aiheutuvien verojen sekä maksujen maksamisesta.',
    },
    acceptTermsButtonText: 'Hyväksy ehdot',
  },
  blanco: {
    pageHeadline: 'Yksityislaina',
    applyForLoan: 'Hae yksityislainaa',
    searchPlaceholder: 'Hae viitteellä, nimellä tai henkilötunnuksella',
    searchPaginationInfo:
      'Näyttää {paginationFirstItemNumber} - {paginationLastItemNumber}. Yhteensä löytyi {totalElements} yksityislainaa',
    returnBackToBlanco: 'Takaisin yksityislainaan',
    loanApplication: 'Lainahakemus',
    signingLinkCopied: 'Allekirjoituslinkki kopioitu!',
    contractSentToCustomer:
      'Olemme lähettäneet asiakkaalle viestin ohjeineen ja odotamme, että velkakirja allekirjoitetaan.',
    loanAmount: 'Lainasumma',
    interestRate: '{interestRate} korko',
    monthlyPayment: 'Kuukausi-maksu',
    month: '{month} kk',
    hideMonthlyCostPerYear: 'Piilota kuukausimaksu, jos maksuaika on erilainen',
    showMonthlyCostPerYear: 'Näytä kuukausimaksu, jos maksuaika on erilainen',
    applicant: 'Hakija',
    coApplicant: 'Lainan takaaja',
    personalId: 'Henkilötunnus',
    other: 'Muut',
    myInvoiceReference: 'Oma laskuviite',
    invoiceReferenceHelperText:
      'Enintään 20 merkkia, vain kirjaimia & numeroita',
    noLeads: 'Ei lainahakemuksia',
    noLeadsYet: 'Et ole hakenut yksityislainoja',
    additionalInformation: 'Lisätietoja',
    enterLoanAmount: 'Syötä lainasumma',
    commissionsEarned: '{commissionsEarned} provisio',
    maxCreditLimitExceeded:
      'Olet syöttänyt liian suuren summan, korkein sallittu summa on {maxCreditLimit}',
    minCreditLimitExceeded:
      'Olet syöttänyt liian pienen summan, pienin sallittu summa on {minCreditLimit}',
    lowestMonthlyCostSummary: 'Erälaskelma',
    costPerMonth: 'euro / kk',
    payOut: 'Maksetaaan',
    payOutSum: '{minPayoutQuota} euro',
    repaymentYears: '{years} vuosien maksuaika',
    repaymentMonths: '{months} kuukauden maksuaika',
    invoiceFee:
      'Tilinhoitomaksu lisätään kuukausierän päälle. Maksu on 0,28% luottosummasta (max 12,50€/kk)',
    repaymentOptions: 'Osamaksuvaihtoehdot',
    addCoApplicant: 'Lisää lainan takaaja',
    hideTerms: 'Piilota ehdot',
    loanInterestCards: {
      title: 'Haluttu kuukausierä',
      loading: 'Tarjousten hakeminen',
      currencyPerMonth: '€/kk',
    },
    repaymentOptionsModal: {
      calculateMonthlyCostByRepaymentMonths:
        'Laske kuukausimaksu jos haluat maksaa lainan nopeammin pois', // ??
      repaymentTime: 'Haluttu takaisinmaksuaika',
      month: '{month} kk',
      dueMonthly: 'Kuukausimaksu',
      dueMonthlyExplanation:
        'Tämä on likimääräinen laskelma summasta joka asiakkaasi pitäisi maksaa kuukausittain, jotta laina saataiisin maksettua takaisin lyhyemmässä ajassa. Laskussa oleva summa on kuiteinkin edelleen asiakkaan pienin mahdollinen kuukausittainen maksuerä. Laskelmassa ei ole otettu huomioon mahdollisia peruutus- sekä järjestlymaksuja.',
      closeModal: 'Sulje maksueräjärjestely',
    },
    priceTag: {
      header: 'Otsikko',
      headerPlaceholder: 'Täytä hintalapun otsikko. Esim. Volvo V60 D4',
      downloadPriceTag: 'Lataa hintalappu (PDF)',
    },
    applicantForm: {
      personalIdPlaceholder: 'PPKKVVCZZZQ',
      phoneNumberPlaceholder: '04XX XXX XXX',
      sourceOfIncome: 'Työsuhteen muoto',
      sourceOfIncomePlaceholder: 'Valitse työsuhde',
      income: 'Kuukausitulot',
      childrenUnder18: 'Lapsia alle 18v',
      childrenUnder18Placeholder: 'Alle 18-v lasten lukumäärä',
      children: {
        noChildren: 'Ei lapsia',
        oneChild: '1 lapsi',
        multipleChildren: '{children} lasta',
      },
      sourceOfIncomes: {
        permanentEmployment: 'Vakituinen',
        selfEmployed: 'Yrittäjä',
        temporaryEmployment: 'Määräaikainen',
        retired: 'Eläkeläinen',
      },
      personalIdNotFound: 'Ei löytynyt henkilöa näillä henkilötunnuksilla',
      tooltips: {
        income: 'Ennen veroja',
      },
      typeOfResidence: 'Asuntomuoto',
      typeOfResidencePlaceholder: 'Valitse asuntomuoto',
      typeOfResidences: {
        rental: 'Vuokra-asunto',
        condo: 'Kerrostalo',
        sublet: 'Alivuokralainen',
        villa: 'Omakotitalo',
      },
    },
    detailedForm: {
      employerName: 'Työnantaja',
      employmentStartedTime: 'Aloituspäivämäärä',
      employmentStartedTimePlaceholder: 'VVVV-KK-PP',
      maritalStatus: 'Siviilisääty',
      maritalStatusPlaceholder: 'Valitse siviliisääty',
      maritalStatuses: {
        single: 'Yksinhuoltaja',
        married: 'Naimissiä',
        cohabiting: 'Sambo',
        divorced: 'Erottu',
        widow: 'Leski',
      },
      typeOfResidence: 'Asuntomuoto',
      typeOfResidencePlaceholder: 'Valitse asuntomuoto',
      typeOfResidences: {
        rental: 'Vuokra-asunto',
        condo: 'Kerrostalo',
        sublet: 'Alivuokralainen',
        villa: 'Omakotitalo',
      },
      livingExpenses: 'Asumiskustannukset kuukaudessa',
      loanExpenses: 'Lainakustannukset kuukaudessa',
      otherExpenses: 'Muut kiinteät kulut kuukaudessa',
      tooltips: {
        livingExpenses:
          'Vuokra/vastike ja sähkö. Ei asuntolainaan liittyviä kustannuksia',
        loanExpenses:
          'Kaikkien lainojen kuukausierät, mukaan lukien asuntolainan kuukausierä',
      },
    },
    sendLead: 'Lähetä lainahakemus',
    applyResponses: {
      applicationAddOwnReference:
        'Jos haluat syöttää oman referenssin voit tehdä sen tästä 👇',
      applicationIsPollingTitle: 'Lainahakemuksesi on lähetetty!',
      applicationIsPolling:
        'Odota pieni hetki, järjestelmä ottaa yhteyttä kaikkiin pankkeihin',
      applicationIsProcessingTitle: 'Lainahakemuksesi käsitellään.',
      applicationIsProcessing:
        'Teemme kivikovaa työtä löytääksemme ratkaisun ja palaamme asiaan pian.',
      accountAnalysis: {
        title: 'Mahdollisuus saada laina!',
        description1:
          'Antamalla meille oikeuden tehdä täydentävän analyysin asiakkaan palkkatilistä, asiakkaalla on isompi mahdollisuus saada lainahakemuksensa hyväksytyksi.',

        description2:
          'Asiakas tekee tämän nopeasti sekä helposti puhelimen kautta.',
        bullet1:
          'Asiakas on saanut tekstiviestin joka sisältää linkkin: {phoneNumber}',
        bullet2:
          'Asiakas avaa linkkin ja antaa samanaikaisesti lupaa // ?? med hjälp av BankID',
        bullet3: 'Palaamme asiaan pian',
      },
      applicationIsApprovedTitle: 'Lainahakemuksesi on hyväksytty!',
      applicationIsApproved:
        'Mahtavaa, siirry hakemukseen  tarkistamaan ehdot ja lähetä sopimus asiakkaallesi.',
      applicationIsRejectedTitle: 'Lainahakemuksesi ei ole hyväksytty',
      applicationIsRejected:
        'Pahoittelemme mutta meillä ei ole mahdollisuutta auttamaan tämän lainahakemuksen kanssa, soita tai kirjoita chatissa jos haluat lisätietoja.',
    },
    buyerAddress: 'Postiosoite',
    supplement: {
      supplementReasonTitle:
        'Hakemuksesi pitää täydentää että voimme hyväksyä sen',
      subtitle:
        'Hakemuksesi on melkein valmis! \n' +
        'Tarvitsemme lisätietoja jotta pystymme jatkamaan hakemuksesi kanssa.',
      actionComplement:
        'Lähetä seuraavat lisätiedot jotta voimme täydentää hakemuksesi',
      actionContactUs: 'Ota yhteyttä, niin autamme sinua',
      coApplicantInformationPrompt: 'Tarvitsemme lisätietoja // ?? medsökande',
      income: 'Tulot',
      certificateOfEmployment: 'Työsopimus',
      incomeVerification: 'Kolme viimeistä palkkatodistusta',
      personalId: 'Henkilötunnus',
      mobilePhoneNumber: 'Puhelinnumero ',
      sourceOfIncome: 'Työsuhde',
      childrenUnder18: 'Lapsia alle 18v',
      startChat: 'Aloita chatti',
      prePopulatedChatContent:
        'Moi, tässä tulee lisätietoja {firstName} {lastName} {personalId}',
    },

    error: {
      title: {
        apply: {
          noAccess: 'Emme pystyneet löytämään hakemuksesi!',
          notFound: 'Emme pystyneet löytämään hakemuksesi!',
          wrongData: 'Jokin meni pieleen',
        },
        invoiceReference: {
          noAccess: 'Jokin meni pieleen',
          notFound: 'Jokin meni pieleen',
          wrongData: 'Virheellinen referenssi',
        },
        detail: {
          noAccess: 'Emme pystyneet löytämään hakemuksesi!',
          notFound: 'Emme pystyneet löytämään hakemuksesi!',
          wrongData: 'Jokin meni pieleen',
        },
        monthlyPayment: {
          noAccess: 'Emme pysty laskemaan kuukausimaksuasi!',
          notFound: 'Emme pysty laskemaan kuukausimaksuasi!',
          wrongData: 'Emme pysty laskemaan kuukausimaksuasi',
        },
        dailyPayment: {
          noAccess: 'Emme pysty laskemaan päivämaksuasi!',
          notFound: 'Emme pysty laskemaan päivämaksuasi!',
          wrongData: 'Emme pysty laskemaan päivämaksuasi',
        },
        priceList: {
          noAccess: 'Emme löydä hinnastoa!',
          notFound: 'Emme löydä hinnastoa!',
        },
        priceTag: {
          noAccess: 'Emme voineet ladata PDF:ä!',
          notFound: 'Emme voineet ladata PDF:ä!',
          wrongData: 'Emme voineet ladata PDF:ä!',
        },
        sendContract: {
          noAccess: 'Jokin meni pieleen',
          notFound: 'Jokin meni pieleen',
          wrongData: 'Jokin meni pieleen',
        },
        creditProducts: {
          noAccess: 'Jokin meni pieleen',
          notFound: 'Jokin meni pieleen',
          wrongData: 'Jokin meni pieleen',
        },
        checkout: {
          negativeBalance: 'Liian vähän pisteitä',
        },
      },
      message: {
        apply: {
          noAccess: 'Lainahakemuksesi ei ole saatavissa.',
          notFound: 'Lainahakemuksesi ei ole saatavissa.',
          wrongData: 'Jokin meni pieleen',
        },
        invoiceReference: {
          noAccess: 'Lainahakemuksesi ei ole saatavissa.',
          notFound: 'Lainahakemuksesi ei ole saatavissa.',
          wrongData:
            'Referenssi voi vaan sisältää kirjaimia sekä lukemia (ei välilyöntejä tai väliviivoja). Enintään 8 merkkiä.',
        },
        detail: {
          noAccess: 'Lainahakemuksesi ei ole saatavissa.',
          notFound: 'Lainahakemuksesi ei ole saatavissa.',
          wrongData: 'Jokin meni pieleen',
        },
        monthlyPayment: {
          noAccess: 'Emme voi näyttää hinnastoa.',
          notFound: 'Emme voi näyttää hinnastoa.',
          wrongData: 'Virheellinen tieto',
        },
        dailyPayment: {
          noAccess: 'Emme voi näyttää hinnastoa.',
          notFound: 'Emme voi näyttää hinnastoa.',
          wrongData: 'Virheellinen tieto',
        },
        priceList: {
          noAccess: 'Emme voi näyttää hinnastoa.',
          notFound: 'Emme voi näyttää hinnastoa.',
        },
        priceTag: {
          noAccess: 'Emme voi näyttää hinnastoa.',
          notFound: 'Emme voi näyttää hinnastoa.',
          wrongData: 'Jokin meni pieleen',
        },
        sendContract: {
          noAccess: 'Emme voi lähettää sopimusta',
          notFound: 'Emme voi lähettää sopimusta',
          wrongData: 'Emme voi lähettää sopimusta',
        },
        creditProducts: {
          noAccess: 'Emme voi näyttää hinnastoa.',
          notFound: 'Emme voi näyttää hinnastoa.',
          wrongData: 'Virheellinen tieto',
        },
        checkout: {
          negativeBalance:
            'Pisteet eivät riitä. Ole hyvä ja muokkaa ostoskoria.',
        },
      },
    },
  },
  leasing: {
    pageHeadline: 'Leasing',
    applyForLeasing: 'Ansök om leasing',
    searchPlaceholder: 'Sök på företagsnamn eller registreringsnummer',
    searchPaginationInfo:
      'Visar {paginationFirstItemNumber} - {paginationLastItemNumber} av totalt {totalElements} leasingansökningar',
    backToLeasing: 'Tillbaka till Leasing',
    noLeads: 'Inga leasingansökningar',
    noLeadsYet: 'Du har inte ansökt om någon leasing ännu.',
    leasingRent: 'Leasinghyra',
    costPerMonthExclVAT: 'eur/kk (exkl. moms)',
    helperTextsPercent: 'Motsvarar {percent} av priset',
    labels: {
      application: 'Leasingansökan',
      financing: 'Finansiering',
      customerInformation: 'Kundinformation',
      object: 'Objekt',
      priceExclVAT: 'Pris (exkl. moms)',
      downPaymentExclVAT: 'Första förhöjd hyra (exkl. moms)',
      repaymentPeriod: 'Leasingperiod',
      residualValueExclVAT: 'Restvärde (exkl. moms)',
      leasingRentExclVAT: 'Leasinghyra (exkl. moms)',
      organizationNumber: 'Organisationsnummer',
      contactPerson: 'Kontaktperson',
      phoneNumber: 'Telefonnummer',
      emailAddress: 'E-postadress',
      brand: 'Märke',
      model: 'Modell',
      yearModel: 'Årsmodell',
      numberPlate: 'Registreringsnummer',
      distanceDriven: 'Mätarställning',
      monthlyPaymentPerMonth: '{monthlyPayment}/månad',
      sendApplication: 'Skicka leasingansökan',
      acceptTerms: 'Acceptera användarvillkoren',
    },
    placeholders: {
      repaymentPeriod: 'Välj leasingperiod',
      organizationNumber: 'XXXXXXXXXX',
      brand: 'Ange märke',
      model: 'Ange modell',
      yearModel: 'Ange årsmodell',
      numberPlate: 'ABC123',
      distanceDriven: '0 mil',
      phoneNumber: '04XX XXX XXX',
    },
    fixFormErrors: 'Korrigera felaktiga värden i formuläret',
    formErrors: {
      Min: 'Måste vara minst {value}',
      Length: 'Längden matchar inte {value}',
      NotEmpty: 'Får ej vara tom',
      Email: 'Ej giltig email-adress',
      TooHigh: 'Får ej vara högre än priset',
      Pattern: 'Felaktigt värde',
    },
    values: {
      months: '{months} månader',
    },
    applicationSuccessfullySent: 'Din ansökan är under behandling.',
    missingOrganization:
      'Kunde inte hitta någon organisation med detta organisationsnumret',
  },
  'inventory-financing': {
    pageHeadline: 'Varastorahotius', // ??
    applyForInventoryFinancing: 'Uus hakemus',
    searchPlaceholder: 'Hae rekisterinumerolla, merkillä tai mallilla',
    searchPaginationInfo:
      'Näyttää {paginationFirstItemNumber} - {paginationLastItemNumber} / {totalElements} varastorahotiushakemuksia',
    noLeads: 'Inga lagerfinansansökningar', // ??
    noLeadsYet: 'Du har inte ansökt om någon lagerfinans ännu.', // ??
    returnToInventoryFinancing: 'Tillbaka till lagerfinansiering', // ??
    returnToDetails: 'Takaisin hakemukseen',
    repaymentDays: 'Enintään {days} päivän makusaika',
    payAbout: 'Maksat n. {amount} päivässä',
    fixFormErrors: 'Korjaa virheelliset arvot lomakkeesa',
    applicationSuccessfullySent: 'Hakemustasi käsitellään parhaillaan',
    overview: 'Lagerfinansöversikt', // ??
    document: 'Dokumentti',
    contractSentToCustomer:
      'Olemme lähettäneet allekirjoituslinkin sinulle teksitviesillä odotamme sopimuksen allekirjoittamista.',
    applicationObservation:
      'HUOM! - Muista ilmoittaa henkilö tai yritys, joka myy auton sinulle. Eli ei omaa yritystäsi.',
    notOnboardedPartner:
      'Lagerfinansprodukten är inte aktiverad för dig än. Kontakta oss så hjälper vi dig.', // ??
    applicationConfirm: {
      loadingTitle: 'Hakemus on lähtetty',
      loadingBody: 'Odota pieni hetki, kuin järjestelmä hakee.',
      approvedTitle: 'Hakemuksesi on hyväksytty',
      approvedBody:
        'Mahtavaa, siirry hakemukseen tarkistaaksesi ehdot ja allekirjoittaaksesi sopimuksen.',
    },
    limit: {
      status: 'Varastorahoituksen limiitti',
      activeCars: 'Aktiiviset autot',
      remaining: 'Jäljellä oleva limiittit',
      total: 'Yhteensä',
      contact: 'Ota yhteyttä',
    },
    labels: {
      object: 'Objekti',
      maxCreditDays: 'Varastopäivät',
      creditAmount: 'Luoton määrä',
      application: 'Hakemuksesi',
      numberPlate: 'Rekisterinumero',
      brand: 'Merkki',
      model: 'Malli',
      yearModel: 'Vuosimalli',
      objectVatStatus: 'Alv-status', // ?
      seller: 'Myyjä',
      financing: 'Rahoitus',
      price: 'Hinta',
      firstInstallment: 'Ensimmäinen osamaksu',
      appliedInterest: 'Kuukausikorko',
      agreementFee: 'Perustamismaksu',
      signing: 'Allekirjoitus',
      authorizedSignatory: 'Valtuutetut allekirjoittajat',
      guarantor: 'Takaaja',
      missingVehicleInformation:
        'HUOM! Ajoneuvon tiedot puuttuvat, tarkista tiedot ja paina "Hae ajoneuvo"',
      errorSameOrganizationNumber:
        'Ette voi syöttää omaa organisaationumeroasi, valitse toinen myyjä.',
      sendApplication: 'Lähetä varastorahotiushakemuksen',
      privateSeller: 'Yksityishenkilö',
      companyContactPerson: 'Yhteyshenkilö',
      organisationNumber: 'Y-tunnukset',
      sellerInfo: 'Myyjä',
      privateSellerName: 'Nimi',
      personalId: 'Henkilötunnus',
      buyer: 'Ostaja',
      guarantorPersonalId: 'Takaajan henkilötunnus',
      guarantorPhoneNr: 'Takajan puhelinumero',
      authorizedSignatoryPersonalId: 'Valtaajan henkilötunnus',
      authorizedSignatoryPhoneNr: 'Valtaajan puhelinumero',
      samePersonPersonalId: 'Takaajan/Valtaajan henkilötunnus',
      samePersonPhoneNr: 'Takaajan/Valtaajan puhelinumero',
      priceCurrency: 'Valuutta',
      downPayment: 'Käteismaksu (vähin {percentage}% ajoneuvon hinnasta)',
      vehicle: 'Ajoneuvo',
      vehiclePrice: 'Ajoneuvon hinta',
      includesVAT: '(sisälten ALV.)',
      guarantorSameAsAuthSign: 'Takaajan on oltava sama kuin valtaaja',
      iAcceptTerms: 'Hyväksyn',
      termsText: 'Käyttöehdot',
      documentName: 'Dokumentinimi',
      noDocumentsToShow: 'Ei dokumentteja näytettävissä',
      authorizedSignatorySigningLink: 'Valtaajan allekirjoituslinkki',
      guarantorSigningLink: 'Takaajan allekirjoituslinkki',
      finalPayment: 'Lopullinen maksu',
      finalPaymentForObject:
        'Rekisterinumeron {plateNumber} luoton takaisinmaksu.',
      firstPayment: 'Ensimmäinen osamaksu',
      paidOutDate: 'Maksettu {paidOutDate}',
      directPayment: 'Maksa heti',
    },
    placeHolders: {
      privateSellerName: 'Syötä nimi',
      companyContactPerson: 'Syötä yhteyshenkilö',
      organisationNumber: 'Syöta Y-tunnus',
      phoneNumber: '05XXXXXXXX',
      organizationNumber: 'XXXXXXXXXX',
      personalId: 'YYYYKKPPXXXX',
      numberPlate: 'ABC123',
      objectVat: 'ALV-auto',
      brand: 'Syötä merkki',
      model: 'Syötä malli',
      yearModel: 'Syötä vuosimalli',
      metricInKilometers: 'Syötä mittarilukema (km)',
    },
    values: {
      usedDaysOfTotalDays: '{usedDays}/{totalDays}:stä',
      usedDays: '{usedDays} varastopv',
      interest: '{interest} korko',
      installmentPercentage: 'Vastaa {percentage}% ajoneuvon hintaa',
      appliedInterest: '{interest}% kuukasikorko',
      agreementFee: '{amount} perustamismaksu',
    },
    'payment-details': {
      choosePaymentMethods: 'Valitse maksutapa',
      payByDirectPayment: 'Maksa heti',
      directPaymentDescription1:
        'MyMoney samarbetar med Finshark för direktbetalning', // ??
      directPaymentDescription2:
        'Du betalar enkelt från ditt önskade bankkonto, med säker verifiering via ditt BankID. Maksat helposti haluamaltasi pankkitililtä', // ??
      inPartnershipWith: 'Yhteistyössä',
      goToPayment: 'Siirry turvalliseen maksuun',
      payByInvoice: 'Maksa laskulla',
      invoiceDescription: 'Maksu suoritetaan laskulla',
      downloadInvoice: 'Lataa lasku',
      changePaymentMethod: 'Vaihda maksutapa',
    },
  },
  'point-shop': {
    pageHeadline: 'Pistekauppa',
    balanceLeft: 'Sinun saldo on: {balanceLeft} p',
    searchPlaceholder: 'Hae tuote',
    searchResultsMatching:
      '{totalElements} Haku osumia, jotka täsmäävät "{termSearched}"',
    filterByPoints: 'Näytä ainoastaan tuotteita jotka voin ostaa',
    searchPaginationInfo:
      'Näyttää {paginationFirstItemNumber} - {paginationLastItemNumber} / {totalElements} tuoteitta',
    stock: {
      expectedInbound: 'Jälein kaupassa {expectedInbound}',
      outOfStock: 'Loppuunmyyty',
    },
    cart: {
      emptyCart: 'Ostoskori on tyhjä',
      multipleProducts: '{itemsInCart} Tuotteita',
      singleProduct: '{itemsInCart} tuote',
      total: 'Yhteensä',
      toCheckout: 'Siirry ostoskoriin',
    },
    returnBackToPointShop: 'Takaisin pistekauppaan',
  },
  'point-shop-article': {
    addToCart: '+ Lisää ostoskoriiin',
  },
  'point-shop-checkout': {
    pageHeadline: 'Ostoskori',
    packageDeliveryTo: 'Paketti toimitetaan seuraavaan osoitteeseen',
    att: 'Huom: {fullName}',
    denyBoxAddress:
      'Valitettavasti emme pysty lähettää paketteja laatikko osoitteisiin, valitse toinen osoite.',
    form: {
      att: 'Huom',
      address: 'Osoite',
      zip: 'Postinumero',
      city: 'Kaupunki',
      errorBoxAddress: 'Emme pysty lähettää laatikkoosoitteisiin',
    },
    sendOrder: 'Lähetä tilaus',
    overspent: 'Pisteet eivät riitä. Ole hyvä ja muokkaa ostoskoria.',
    insufficientQuantity:
      'Teidän valitsemasi artikkeli “{articleName}” on jälkitoimituksessa.',
    confirmation: {
      orderHasBeenSentTitle: 'Tilauksesi on vastaanotettu!',
      orderHasBeenSentDescription:
        'Olemme vastaanottaneet tilauksesi ja lähetämme vahvistuksen sinulle hetken kuluttua',
      orderHasBeenPartiallySentTitle: 'Tilauksesi on vastaanotettu',
      orderHasBeenPartiallySentDescription:
        'Huom, valitettavasti kaikki sinun tuotteesi ei ole varastossa tällä hetkellä, tämä tarkoittaa, että saatat saada osatoimituksen. Ota meihin yhteyttä chattissaa, sähkopostitse partner@mymoney.se, tai puhelimitse 000000, jos sinulla on kysyttävää', // ??
      orderSentErrorTitle: 'Harmi, tilauksesi epäonnistui',
      orderSentErrorDescription:
        'Valitettavasti jotain meni pieleen tilauksesi kanssa. Ota meihin yhteyttä chattissaa, sähkopostitse partner@mymoney.se, tai puhelimitse 000000, niin autamme sinua',
    },
  },
  report: {
    pageHeadline: 'Rapportit',
    subNavigation: {
      businessFinanceMonthlyStatement: 'Varastorahoituksen kuukausiraportti',
      activeBusinessFinanceStatement: 'Aktiivit varastorahoitukset',
    },
    tableHeader: {
      month: 'Kuukausi',
      interest: 'Korko',
    },

    error: {
      title: {
        list: {
          noAccess: 'Emme pystyneet löytämään rapporttisi!',
          notFound: 'Emme pystyneet löytämään rapporttisi!',
          wrongData: 'Jokin meni pielen',
          notAcceptable: 'Jokin meni pielen',
        },
      },
      message: {
        list: {
          noAccess: 'Raporttit eivät ole saatavilla',
          notFound: 'Raporttit eivät ole saatavilla.',
          wrongData: 'Raporttit eivät ole saatavilla.',
          notAcceptable: 'Kuukausi ei ole saatvilla laddalattavaksi',
        },
      },
    },
  },
  commission: {
    pageHeadline: 'Provisio',
    totalCommissionCurrentYear: 'Maksettu provisio {year}:',
    tableHeader: {
      month: 'Kuukausi',
      commission: 'Provisio',
      adjustment: 'Säätö', // ?
      paidOut: 'Maksettu',
    },
    onGoing: 'Käsittelleyssä',

    error: {
      title: {
        list: {
          noAccess: 'Emme löytäneet provisioita',
          notFound: 'Emme löytäneet provisioita',
          wrongData: 'Jokin meni pielen',
          notAcceptable: 'Jokin meni pielen',
        },
      },
      message: {
        list: {
          noAccess: 'Provisio eivät ole saatavilla',
          notFound: 'Provisio eivät ole saatavilla.',
          notAcceptable: 'Kuukausi ei ole saatvilla laddalattavaksi',
        },
      },
    },
  },
  organisation: {
    pageHeadline: 'Yritys',
    usersHeadline: 'Käyttäjät',
    paginationShowing: 'Näytetään {first}-{last} / {totalElements} käyttäjää',
    searchPlaceholder: 'Etsi nimellä',
    label: {
      companyName: 'Yrityksen nimi',
      organisationNumber: 'Y-tunnus',
      emailAddress: 'Sähköpostiosoite',
      phoneNumber: 'Puhelinnumero',
      bankAccountType: 'Tilin muoto', // ?
      bankAccountNumber: 'Tilinumero',
      bankAccountClearingNumber: 'Viitenumero',
      employeeName: 'Nimi',
      facilityName: 'Yrityksen nimi',
      IBAN: 'IBAN',
      BIC: 'BIC',
    },
    accountTypes: {
      BANKGIRO: 'Pankkisiirto',
      PLUSGIRO: 'Plustilisiirto', // ?
      BBAN: 'Pankkitili',
      IBAN: 'IBAN',
      FI_BANK: 'Pankkitili',
      MISSING: 'Missing',
    },
  },
  market: {
    pageHeadline: 'Markkina',
    numberPlate: 'Rekisterinumero: {numberPlate}',
    filterResults: '{filterAmount} tuoteita varastossa',
    backToMarket: 'Takaisin markkinoille',
    purchase: 'Ostos',
    purchaser: 'Ostaja',
    companyDetails: 'Yrityksen tiedot',
    acceptTerms: 'Hyväksy käyttöehdot',
    iAcceptTerms: 'Hyväksyn käyttöehdot Payn markkinalle',
    observe: 'Huomio',
    bindingPurchase: 'Ostosi on sitova',
    soldAsIs: 'Auto myydään kuten on',
    sellerSendsInvoice: 'Myyjä lähettää sinulle laskun auton kokonais summasta',
    transportOfVehicle: 'Ajoneuvon kuljetus ei sisälly hintaan',
    confirmPurchase: 'Viimesitelle ostotapahtuma',
    confirmedPurchaseTitle: 'Autossi on tilattu',
    confirmedPurchaseDialog:
      'Olemme vastaanottaneet tilauksesi, ja saat pian sähköpostitse vahvistuksen, jossa löydät lisätietoja',
    error: {
      title: {
        list: {
          notFound: 'Ei löytynyt yhtään ilmoitusta',
        },
        detail: {
          notFound: 'Emme löytäneet autoa!',
        },
        confirm: {
          wrongData: 'Jokin meni pielen',
          notFound: 'Auton ostaminen ei ollut mahdollista!',
        },
      },
      message: {
        list: {
          notFound: 'Ei löytynyt yhtään ilmoitusta.',
        },
        detail: {
          notFound: 'Autoa ei ole enää saatavilla.',
        },
        confirm: {
          wrongData: 'Ostoa ei voittu toteuttaa.',
          notFound: 'Autoa, jota yrytit ostaa, ei ole enään saatavilla.',
        },
      },
    },
    ads: {
      fuelTypes: {
        DIESEL: 'Diesel',
        ELECTRIC: 'Sähkö',
        GASOLINE: 'Bensiini',
        HYBRID: 'Hybridi',
      },
      gearTypes: {
        AUTOMATIC: 'Automaatti',
        MANUAL: 'Manuelli',
      },
    },
    fuelTypes: {
      DIESEL: 'Diesel',
      ELECTRIC: 'Sähkö',
      GASOLINE: 'Bensiini',
      HYBRID: 'Hybridi',
    },
    gearTypes: {
      AUTOMATIC: 'Automaatti',
      MANUAL: 'Manuaali',
    },
    distanceMil: '{distance} km', // ?
    distanceKilometers: '{distance} km',
  },
  marketTermsModal: {
    modalTitle: 'Käytäjäehdot Market',
    aboutService: {
      title: 'Tietoja palvelusta',
      text: 'My Money markettissa (alla kutsuttu "mainossivu") voi yhteistyökumppanit (alla kutsuttu "Kauppiaat") ostaa tuoteitta kolmansilta osapuolilta (alla kutsuttu "Myyjä"). Henkilöit töissä Kauppiaan luonna (alla kutsuttu "Käyttäjät") saavat edustajana yritykselle käyttäjätillin. Käyttämällä Markkina-paikkaa kaupiat sekä käyttäjät hyväksyvät käytöehdot ja noudatavat niitä ("Ehdot"). Palvelun tarjoaa MyMoney OY, 3303458-1 (”MyMoney”). MyMoney päätää vapaasti, miktä Kauppiaat ja Käyttäjät voivat käyttää palvelua.',
    },
    agreementAndOrder: {
      title: 'Sopimus sekä tilaus',
      text: 'Jota Kauppiaat sekä Käyttäjäät voivat tehdä ostoksia Markkina-paikkan kautta, heidän on hyväksyttävä ehdot. Ostot Markkina-paikan kautta Kauppiaat sekä Myyjät hyväksyvät sopimuksen. MyMoney tarjoaa Markkina-paikkan ja välittää tietoja Kauppiaan sekä Myyjien/Käyttäjien välillä, mutta MyMoney ei ole millään tavoin vastussa myytyjen tuoteiden kunnosta, toimituksessta, tavaran kuvaukseen tarkkuudesta, tai mistän muusta ostoon littyyvään. Myyjällä on oikeius lopulliseen myyntiin ja kaikkiin Markkinapaikan kuva- ja kirjoitusvirheisiin, kuten virheisiin tuotekuvauksessa tai teknisissä eritelmissä, virheellisiin hintoihin ja hinnanmuutoksiin tai virheellisiin tietoihin siitä, onko tuote varastossa. Myjjällä on oikeus korjata yllä mainitut liityviin virheisin sekä muuttaa tai päivittää tietoja milloin tahansa. Kaikki Markkinapaikan kuvukset ovat vain havainnollistamis-tarkoituksessa. Havainnollistamis-tarkoitukset eivät voi taata tuoteiden tarkkaa ulkonäköa, toimintuja tai alkuperää.MyMoney ei ole vastuussa Markkinapaikalla olevista tiedoista, jotka tulevat Myyjältä.  Osto joka on tehty Marketpaikalla on sitova ja Kauppias lähettää Myyjälle laskun sovitusta kauppahinnast.',
    },
    deliveryAndTransport: {
      title: 'Toimitus sekä kuljetus',
      text: 'Ostetut tuotet toimitetaan, kuin Kauppias on vastaanottanut sovitun kauppahinnan. Mahdolliset toimitustavat sekä toimituskulut ilmenevät tuotteen ilmoituksesta ja niistä sovitaan Kauppiaan ja Myyjien/Käyttäjien kesken.',
    },
    acceptTermsButtonText: 'Hyävksy käyttöehdot',
  },
  'my-profile': {
    userInformation: 'Käytäjätunnukset',
    updateUserInformation: 'Päivitä käytäjätunnukset',
    userInformationUpdated: 'Käytäjätunnukset päivitetty',
    userInformationUpdatedDescription: 'Käyttäjätietosi ovat muuttuneet',

    notifications: 'Ilmoitukset',
    notifyWhen: 'Ilomita minulle seuraavista',
    notifyOn: 'Ilmoita minulle kautta',
    statusLabels: {
      bankApproved: 'Hyväksyttyjä hakemuksia',
      bankDenied: 'Hylättyjä hakemuksia',
      signed: 'Allekirjoittetut hakemukset',
      paidOut: 'Maksetut hakemukset',
      bankSupplement: 'Täydennä',
    },
    channelLabels: {
      email: 'Sähköpostilla',
      sms: 'Tekstiviestillä',
    },
    language: {
      title: 'Kiel / Språk',
      subtitle:
        'Haluan nähdä järjestelmän ja vastaanottaa viestintää: \n' +
        'Jag vill se systemet och få kommunikation på:',
      labelSV: 'Svenska - SV',
      labelFI: 'Suomeksi - FI',
    },
    error: {
      title: {
        userInformation: {
          noAccess: 'Emme pystyneet päivittämään käyttäjätiedot',
          notFound: 'Emme pystyneet päivittämään käyttäjätiedot!',
          wrongData: 'Jokin meni pielen',
        },
        subscriptions: {
          noAccess: 'Emme pystyneet päivittämään ilmoituksia!',
          notFound: 'Emme pystyneet päivittämään ilmoituksia!',
          wrongData: 'Jokin meni pielen',
        },
      },
      message: {
        userInformation: {
          noAccess:
            'Emme pystyneet päivittämään käyttäjätiedot tällä hetkellä.',
          notFound:
            'Emme pystyneet päivittämään käyttäjätiedot tällä hetkellä.',
          wrongData: 'Jokin meni pielen',
        },
        subscriptions: {
          noAccess: 'Emme pystyneet päivittämään ilmoituksia tällä hetkellä.',
          notFound: 'Emme pystyneet päivittämään ilmoituksia tällä hetkellä.',
          wrongData: 'Jokin meni pielen',
        },
      },
    },
  },
  maintenance: {
    title: 'Huolto käynnissä',
    body: 'Avataan pian uudelleen.',
    reload: 'Lataa sivu uudestaan',
  },
  'vehicle-information': {
    noMatch: 'Ei löytynut ajonneuvoa rekisterinumerolla "{numberPlate}".',
    errorMessage:
      'Haku rekisterillä vastaan ei ole tällä hetkellä mahdollinen. Syötä ajonneuvon tiedot manuaalisesti',
    labels: {
      metricInKilometers: 'Mittarilukema (km)',
      getVehicle: 'Hae ajoneuvo',
      numberPlate: 'Rekisterinumero',
    },
    placeholders: {
      metricInKilometers: 'Syöta mittarilukema (km)',
      numberPlate: 'ABC123',
    },
  },
};
